<template>
    <div class="container">
        <contact-us-modal v-if="application.mobile && application.mobile.length === 10" :hp="hp" :aid="application.slug" :fn="application.first_name" :ln="application.last_name" :em="application.email" :pn="application.mobile" :msg="contact_msg"/>
        <contact-us-modal v-else :hp="hp" :aid="application.slug"/>
        <div class="row justify-content-center">
            <div class="col-3 d-md-none">
                <svg id="step-progress" class="radial-progress" data-percentage="0" viewBox="0 0 80 80">
                    <circle class="incomplete" cx="40" cy="40" r="35"></circle>
                    <circle class="complete" cx="40" cy="40" r="35"></circle>
                    <text class="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">{{ step }} of 8</text>
                </svg>
            </div>
            <div class="col-9 d-flex d-md-none">
                <h2 class="my-auto">{{ steps[step] }}</h2>
            </div>
            <div class="col-xl-9 mt-4 mt-md-0">
                <div class="d-none d-md-flex progress mb-3" style="height: 22px;">
                    <div class="progress-bar text-black small fw-bold" role="progressbar" :style="'width: '+(step / 8 * 100)+'%'" :aria-valuenow="step" aria-valuemin="1" aria-valuemax="8">Step {{ step }} of 8</div>
                </div>
                <div v-if="step === 1" class="card shadow-sm">
                    <div class="card-header bg-dark text-white" v-text="steps[step]"/>
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="saveStep1BasicInfo()">
                            <div class="row">
                                <div class="mb-3 col-md-6 d-none">
                                    <label for="company" class="form-label required">Company</label>
                                    <input name="company" type="text" class="form-control" id="company">
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="first_name" class="form-label required">First Name</label>
                                    <input v-model="application.first_name" type="text" :class="errorClass('first_name')" id="first_name" required>
                                    <span v-if="hasError('first_name')" class="invalid-feedback" role="alert" v-html="getError('first_name')"/>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="last_name" class="form-label required">Last Name</label>
                                    <input v-model="application.last_name" type="text" :class="errorClass('last_name')" id="last_name" required>
                                    <span v-if="hasError('last_name')" class="invalid-feedback" role="alert" v-html="getError('last_name')"/>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="email" class="form-label required">Email Address</label>
                                    <input v-model="application.email" type="email" :class="errorClass('email')" id="email" required>
                                    <span v-if="hasError('email')" class="invalid-feedback" role="alert" v-html="getError('email')"/>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="mobile" class="form-label required">Cell Number</label>
                                    <input v-model="application.mobile" type="text" pattern="[0-9]*" :class="errorClass('mobile')" id="mobile" minlength="10" maxlength="10" required>
                                    <span v-if="hasError('mobile')" class="invalid-feedback" role="alert" v-html="getError('mobile')"/>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="dob" class="form-label required">Date of birth</label>
                                    <input v-model="application.dob" type="date" :class="errorClass('dob')" id="dob" minlength="10" maxlength="10" required>
                                    <span v-if="hasError('dob')" class="invalid-feedback" role="alert" v-html="getError('dob')"/>
                                </div>
                                <div class="col-md-10 offset-md-1">
                                    <div class="border border-2 rounded-pill border-dark mb-4 py-2 px-3">
                                        <div class="form-check ">
                                            <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_privacy">
                                                I have read and accept the
                                                <a class="text-decoration-none" href="/privacy-policy" target="_blank">Privacy Policy</a>
                                            </label>
                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_privacy" :true-value="1" :false-value="0" id="cb_privacy" required>
                                        </div>
                                    </div>
                                    <div class="card bg-light">
                                        <div class="card-body fw-bold small font-helv text-center">
                                            *Terms and conditions: You qualify to receive a 10% discount on the monthly mechanical breakdown warranty premium if you complete the purchase of the product on this site.
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!loading" class="col-12 mt-4">
                                    <a class="btn btn-secondary float-start" data-bs-toggle="modal" data-bs-target="#contactForm" href="#contactForm">
                                        <i class="fas fa-fw fa-phone me-1"></i> Call Me
                                    </a>
                                    <button type="submit" class="btn btn-primary float-end" :disabled="!checkboxes.cb_privacy">
                                        Continue <i class="fas fa-fw fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="loading" class="card-footer bg-primary text-center">
                        <strong class="h4">Loading...</strong>
                        <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                    </div>
                </div>
                <div v-else-if="step === 2" class="card shadow-sm">
                    <div class="card-header bg-dark text-white" v-text="steps[step]"/>
                    <div class="card-body">
                        <div class="col-md-8 offset-md-2 mb-5">
                            <div class="card bg-primary border-primary">
                                <div class="card-body fw-bold small font-helv text-center">
                                    Get a discount on your monthly premium by completing the application online.<br>
                                    T&amp;Cs apply*
                                </div>
                            </div>
                        </div>
                        <form class="needs-validation" @submit.prevent="saveStep2VehicleInfo()">
                            <div :key="any_number" class="row">
                                <div class="mb-3 col-md-3">
                                    <label for="year" class="form-label required">Vehicle Year</label>
                                    <select v-model="vehicle.year" :class="errorClass('year', 'form-select')" id="year" @change="getVehicleModels(true)" required>
                                        <option :value="null">Select year</option>
                                        <option v-for="year in options.years" :value="year">{{ year }}</option>
                                    </select>
                                    <span v-if="hasError('year')" class="invalid-feedback" role="alert" v-html="getError('year')"/>
                                </div>
                                <div class="mb-3 col-md-4">
                                    <label for="make" class="form-label required">Vehicle Make</label>
                                    <select v-model="vehicle.make" :class="errorClass('make', 'form-select')" id="make" @change="getVehicleModels(true)" required>
                                        <option :value="null">Select make</option>
                                        <option v-for="make in options.makes" :value="make">{{ make }}</option>
                                    </select>
                                    <span v-if="hasError('make')" class="invalid-feedback" role="alert" v-html="getError('make')"/>
                                </div>
                                <div class="mb-3 col-md-5">
                                    <label for="model_id" class="form-label required">Vehicle Model</label>
                                    <select v-model="vehicle.model_id" :class="errorClass('model_id', 'form-select')" id="model_id" @change="getVehicleDetails" :disabled="vehicle_models === null" required>
                                        <option :value="null">Select model</option>
                                        <option v-for="model in vehicle_models" :value="model.id">{{ model.model }}</option>
                                    </select>
                                    <span v-if="hasError('model_id')" class="invalid-feedback" role="alert" v-html="getError('model_id')"/>
                                </div>
                                <div class="mb-3 col-md-3">
                                    <label for="use_type" class="form-label required">Vehicle Use Type
                                        <a href="#" title="More info" data-bs-toggle="modal" data-bs-target="#vehicleUseTypeBizModal"><i class="fas fa-sm fa-fw fa-info-circle"></i></a></label>
                                    <select v-model="vehicle.use_type" :class="errorClass('use_type', 'form-select')" id="use_type" required>
                                        <option :value="null">Select use type</option>
                                        <option v-for="use_type in options.use_types" :value="use_type">{{ use_type }}</option>
                                    </select>
                                    <span v-if="vehicle.use_type === 'Commercial'" class="">
                                        <a href="#" class="text-danger text-decoration-none small" title="More info" data-bs-toggle="modal" data-bs-target="#vehicleUseTypeModal">
                                            <span class="fw-bold">Commercial use is not covered</span>
                                            <i class="fas fa-fw fa-info-circle text-danger"></i>
                                        </a>
                                    </span>
                                    <span v-else-if="hasError('use_type')" class="invalid-feedback" role="alert" v-html="getError('use_type')"/>
                                </div>
                                <div class="mb-3 col-md-4">
                                    <label for="odometer" class="form-label required">Estimated odometer (in KM)</label>
                                    <input v-model="vehicle.odometer" type="text" pattern="[0-9]*" :class="errorClass('odometer')" id="odometer" maxlength="7" required>
                                    <span v-if="hasError('odometer')" class="invalid-feedback" role="alert" v-html="getError('odometer')"/>
                                </div>
                                <div class="mb-3 col-md-4">
                                    <label for="reg_no" class="form-label required">Vehicle Registration Number</label>
                                    <input v-model="vehicle.reg_no" type="text" pattern="[a-zA-Z0-9]+" :class="errorClass('reg_no')" minlength="3" maxlength="9" id="reg_no" required/>
                                    <span v-if="hasError('reg_no')" class="invalid-feedback" role="alert" v-html="getError('reg_no')"/>
                                </div>
                                <div class="col-md-10 offset-md-1 my-4 text-center">
                                    <div class="card bg-light">
                                        <div class="card-body fw-bold small font-helv text-center">
                                            Please note that it is your responsibility to inform AA Warranties immediately should the estimated mileage, recorded on this quotation, differ by 10% or more from the actual mileage of your vehicle.
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!loading" class="col-12 mt-4">
                                    <button type="button" class="btn btn-primary float-start" @click="stepBack()">
                                        <i class="fas fa-fw fa-arrow-left"></i> Back
                                    </button>
                                    <button type="submit" class="btn btn-primary float-end">
                                        Continue <i class="fas fa-fw fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal fade" id="vehicleUseTypeModal" tabindex="-1" aria-labelledby="vehicleUseTypeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-body p-md-4">
                                    <p class="fw-bold">Commercial use is not covered, you may not use your vehicle for commercial travelling or as a tool of trade, for example:</p>
                                    <ul>
                                        <li>using your vehicle as a courier or delivery vehicle;</li>
                                        <li>renting out your vehicle for use by others;</li>
                                        <li>using your vehicle to carry passengers for reward, such as a taxi or limousine (excluding lift clubs);</li>
                                        <li>response vehicles</li>
                                    </ul>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="vehicleUseTypeBizModal" tabindex="-1" aria-labelledby="vehicleUseTypeBizModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-body p-md-4">
                                    <p class="fw-bold">What is the difference between commercial and business use?</p>
                                    <p>Business usage refers to the use of a vehicle as part of an insured's work function. For example, when an insured has to travel to clients or attend meetings outside of his or her primary place of employment. A vehicle that is insured for business use will also be covered for private use.</p>
                                    <p>Commercial use covers vehicles used to generate an income. Examples of commercial use include the use of a vehicle to carry goods for business purposes, to carry fare-paying passengers or for trade purposes.</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="loading" class="card-footer bg-primary text-center">
                        <strong class="h4">Loading...</strong>
                        <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                    </div>
                </div>
                <div v-else-if="step === 3" class="card shadow-sm">
                    <div class="card-header bg-dark text-white" v-text="steps[step]"/>
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="saveStep3VehicleCoverInfo()">
                            <div class="flex-order d-lg-flex flex-column">
                                <div class="order-2 mb-3">
                                    <div class="row">
                                        <div v-if="cover_options === null" class="fw-bold text-center mb-3">Fetching vehicle warranty covers...</div>
                                        <div v-else-if="total_covers >= 1 && !this.application.prequalifier" class="mb-3">
                                            <h3>QUALIFYING COVER OPTIONS FOR YOUR VEHICLE</h3>
                                            <p>AA WARRANTY CUSTOMERS
                                                <strong>SAVED OVER R10,000 PER YEAR</strong> IN PREMIUMS BY BUYING ONLINE
                                            </p>
                                            <p>Click to select your preferred cover option. You must select an option to continue.</p>
                                        </div>
                                        <div v-else-if="total_covers === 0" class="offset-md-2 col-md-8 fw-bold text-center bg-light mt-md-3 p-4">
                                            <div>
                                                <p>Unfortunately we were unable to find any warranty covers for this vehicle.</p>
                                                <p>Please get in touch with one of our agents if you would like further assistance.</p>
                                            </div>
                                            <div class="mt-4">
                                                <button type="button" class="btn btn-primary" @click="stepBack()">
                                                    <i class="fas fa-fw fa-pencil-alt"></i> Edit Vehicle
                                                </button>
                                                <a class="btn btn-secondary m-1 px-4" data-bs-toggle="modal" data-bs-target="#contactForm" href="#contactForm">
                                                    <i class="fas fa-fw fa-phone me-1"></i> Call Me
                                                </a>
                                            </div>
                                        </div>
                                        <div v-if="cover_options" v-for="(option, index) in cover_options" :key="index" v-show="showPlan(index)" class="mb-3 col-sm-6 col-lg-4">
                                            <div class="card shadow" :style="'cursor: pointer; '+(vehicle_cover && option.id === vehicle_cover.id ? 'border: solid 3px #000;' : null)" @click="selectVehicleCover(option.id)">
                                                <div class="card-header text-uppercase">
                                                    <strong>{{ options.cover_plans[option.plan_id]['plan'] }}</strong>
                                                </div>
                                                <div class="card-body small">
                                                    <div class="d-flex align-items-center mb-3">
                                                        <span class="fw-bold">Cover limit:</span>
                                                        <span class="ms-3" style="font-size: 20px">{{ options.cover_plans[option.plan_id]['cover'] }}</span>
                                                    </div>
                                                    <div v-if="!this.application.prequalifier">
                                                        <div class="d-flex mb-3">
                                                            <div class="pricing">
                                                                <span style="font-size: 20px;" class="text-black">R&nbsp;{{ option.price_discount }}</span><br>
                                                                <span style="font-size: 16px; text-decoration: line-through">R&nbsp;{{ option.price_total }}</span>
                                                            </div>
                                                            <div class="discount ms-3">
                                                                <img src="images/discount.png" alt="10% online discount" class="img-fluid" style="width: 120px;"/>
                                                            </div>
                                                        </div>
                                                        <div class="select-btn">
                                                            <span class="btn btn-primary w-100">Select
                                                                <i class="fas fa-fw fa-arrow-right"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="toggle_plans && total_covers > 3" class="d-flex align-content-center">
                                            <button type="button" class="btn btn-light" @click="toggle_plans = !toggle_plans">
                                                <i class="fas fa-fw fa-arrow-down"></i> Show more cover options
                                            </button>
                                        </div>
                                        <div v-if="!loading && vehicle_cover" class="col-12 mt-4">
                                            <button type="button" class="btn btn-primary float-start" @click="stepBack()">
                                                <i class="fas fa-fw fa-arrow-left"></i> Back
                                            </button>
                                            <button type="submit" class="btn btn-primary float-end" :disabled="!(cover_options && vehicle_cover !== null) && !this.application.prequalifier">
                                                Continue <i class="fas fa-fw fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="order-1 mb-3">
                                    <div class="row" v-if="total_covers >= 1">
                                        <div class="col-md-7">
                                            <h3>COVERED PARTS</h3>
                                            <p class="d-none d-md-block">We will repair or replace vehicle parts up to the cover limit of your choice. The parts listed below are covered by AA Warranty policies.</p>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <ul class="m-0">
                                                        <li>Engine</li>
                                                        <li>Manual &amp; Automatic Gearbox</li>
                                                        <li>Differential</li>
                                                        <li>Trans-axle (front wheel drive unit)</li>
                                                        <li>Propeller shaft</li>
                                                        <li>Drive shafts</li>
                                                        <li>Suspension - front and rear</li>
                                                        <li>Braking system</li>
                                                        <li>Steering mechanism</li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-6">
                                                    <ul>
                                                        <li>Fuel system</li>
                                                        <li>Cooling system</li>
                                                        <li>Electrical parts</li>
                                                        <li>Electronic ignition</li>
                                                        <li>Electronic computer parts</li>
                                                        <li>Air conditioner / heating</li>
                                                        <li>Wheel bearings</li>
                                                        <li>Turbo or super chargers / air pump</li>
                                                        <li>Additional components</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="border border-3 p-3">
                                                <h3>BENEFITS</h3>
                                                <ul class="cover-benefits">
                                                    <li><i class="icon cover"></i> UP TO R100,000 COVER</li>
                                                    <li><i class="icon hire"></i> CAR HIRE</li>
                                                    <li><i class="icon roadside"></i> ROADSIDE ASSISTANCE</li>
                                                    <li><i class="icon repair"></i> AVOID REPAIR COSTS</li>
                                                    <li><i class="icon cars"></i> IDEAL FOR OLDER CARS</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="loading" class="card-footer bg-primary text-center">
                        <strong class="h4">Loading...</strong>
                        <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                    </div>
                </div>
                <div v-else-if="step === 4" class="card shadow">
                    <div class="card-header bg-dark text-white" v-text="steps[step]"/>
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="saveStep4UnderwritingInfo()">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table">
                                        <tbody>
                                            <tr v-for="(details,key) in options.underwriting">
                                                <td>
                                                    <span class="fw-bold">{{ details.question }}
                                                        <a v-if="options.underwriting[key].description" href="#" @click.prevent="toggleInfoBox(key)"><i class="fas fa-sm fa-fw fa-info-circle"></i></a></span>
                                                    <span v-if="options.underwriting[key]['toggled']" class="small"><br><em>{{ details.description }}</em></span>
                                                    <span v-if="showUnderwritingWarning(vehicle[key], details.fails_on)" class="text-danger small fw-bold"><br>{{ details.error }}</span>
                                                </td>
                                                <td width="150">
                                                    <div class="btn-group float-end" role="group" id="aa_member" :aria-label="details.question">
                                                        <input type="radio" class="btn-check" v-model="vehicle[key]" :id="key + '_yes'" :value="1" autocomplete="off">
                                                        <label class="btn btn-outline-primary text-dark px-3 px-md-4" :for="key + '_yes'">Yes</label>
                                                        <input type="radio" class="btn-check" v-model="vehicle[key]" :id="key + '_no'" :value="0" autocomplete="off">
                                                        <label class="btn btn-outline-primary text-dark px-3 px-md-4" :for="key + '_no'">No</label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-if="!loading" class="col-12 mt-4">
                                    <button type="button" class="btn btn-primary float-start" @click="stepBack()">
                                        <i class="fas fa-fw fa-arrow-left"></i> Back
                                    </button>
                                    <button type="submit" class="btn btn-primary float-end" :disabled="hasNullUnderwriting() || hasFailedUnderwriting()">
                                        Continue <i class="fas fa-fw fa-arrow-right"></i>
                                    </button>
                                    <a v-if="hasFailedUnderwriting()" class="btn btn-secondary float-end me-2" data-bs-toggle="modal" data-bs-target="#contactForm" href="#contactForm">
                                        <i class="fas fa-fw fa-phone me-1"></i> Request a callback
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="loading" class="card-footer bg-primary text-center">
                        <strong class="h4">Loading...</strong>
                        <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                    </div>
                </div>
                <div v-else-if="step === 5" class="card shadow">
                    <div class="card-header bg-dark text-white" v-text="steps[step]"/>
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="saveStep5QuoteSummary()" v-if="!loading">
                            <div class="row">
                                <div class="col-12 table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <th>Vehicle</th>
                                                <th>Cover</th>
                                                <th>Price</th>
                                                <th></th>
                                            </tr>
                                            <tr v-for="v in vehicles">
                                                <td>
                                                    <span class="fw-bold">{{ v.model }}</span><br>
                                                    <span class="small text-muted">{{ v.year }} - {{ v.make }}</span>
                                                    <span class="small text-muted d-none d-md-inline-block">({{ v.reg_no }})</span>
                                                </td>
                                                <td>
                                                    <span class="fw-bold text-uppercase">{{ options.cover_plans[v.cover.plan_id]['plan'] }}</span><br>
                                                    <span class="small text-muted">{{ options.cover_plans[v.cover.plan_id]['cover'] }}</span>
                                                </td>
                                                <td v-if="v.cover.price_total === v.cover.price_discount">
                                                    <span class="fw-bold">R{{ v.cover.price_discount }}</span>
                                                </td>
                                                <td v-else>
                                                    <del class="fw-bold">R{{ v.cover.price_total }}</del>
                                                    <br>
                                                    <span class="fw-bold">R{{ v.cover.price_discount }}*</span>
                                                </td>
                                                <td>
                                                    <button class="btn btn-sm btn-primary m-1 d-inline-block d-md-none" type="button" title="Edit Vehicle" @click="editVehicle(v.slug)">
                                                        <i class="fas fa-sm fa-fw fa-pencil-alt"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-primary m-1 d-none d-md-inline-block" type="button" title="Edit Vehicle" @click="editVehicle(v.slug)">
                                                        <i class="fas fa-fw fa-pencil-alt"></i> Edit
                                                    </button>
                                                    <button class="btn btn-sm btn-primary m-1 d-inline-block d-md-none" type="button" title="Remove Vehicle" @click="removeVehicle(v.slug)">
                                                        <i class="fas fa-sm fa-fw fa-trash-alt"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-primary m-1 d-none d-md-inline-block" type="button" title="Remove Vehicle" @click="removeVehicle(v.slug)">
                                                        <i class="fas fa-fw fa-trash-alt"></i> Remove
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="cover_savings !== 0">
                                                <td colspan="4">
                                                    <small class="text-black-50"><em>*Price includes 10% discount. That's a saving of R{{ cover_savings }} per year!</em></small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <button v-if="!loading" type="button" class="btn btn-primary mt-2 px-lg-5" title="Add Vehicle" @click="addVehicle()">
                                                        <i class="fas fa-fw fa-plus"></i> Add Vehicle
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="row mt-4">
                                        <div class="col-md-10 offset-md-1">
                                            <div class="card bg-light">
                                                <div class="card-body fw-bold small font-helv text-center">
                                                    Complete your application online and pay the discounted premium listed above. Please note: if you exit before completing the process, you will not be eligible for the discount.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!loading" class="col-12 mt-4">
                                    <a class="btn btn-secondary float-start" data-bs-toggle="modal" data-bs-target="#contactForm" href="#contactForm">
                                        <i class="fas fa-fw fa-phone me-1"></i> Call Me
                                    </a>
                                    <button type="submit" class="btn btn-primary float-end">
                                        Continue <i class="fas fa-fw fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="loading" class="card-footer bg-primary text-center">
                        <strong class="h4">Loading...</strong>
                        <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                    </div>
                </div>
                <div v-else-if="step === 6">
                    <form class="needs-validation" @submit.prevent="saveStep6PersonalInfo()">
                        <div class="card shadow-sm">
                            <div class="card-header bg-dark text-white">
                                ID Information
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="mb-3 col-md-6">
                                        <label for="id_no" class="form-label required">Identity Number</label>
                                        <input v-model="application.id_no" type="text" pattern="[0-9]*" :class="errorClass('id_no')" minlength="13" maxlength="13" id="id_no" required>
                                        <span v-if="hasError('id_no')" class="invalid-feedback" role="alert" v-html="getError('id_no')"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card shadow-sm mt-4">
                            <div class="card-header bg-dark text-white">
                                AA Information
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="mb-3 col-md-6 text-md-center">
                                        <label for="cb_aa_member" class="form-label required">Are you an existing AA member?</label><br>
                                        <div class="btn-group" role="group" id="cb_aa_member" aria-label="Are you an existing AA member?">
                                            <input type="radio" class="btn-check" v-model="checkboxes.cb_aa_member" id="aa_mem_yes" :value="1" autocomplete="off">
                                            <label class="btn btn-outline-primary text-dark px-3 px-5" for="aa_mem_yes">Yes</label>
                                            <input type="radio" class="btn-check" v-model="checkboxes.cb_aa_member" id="aa_mem_no" :value="0" autocomplete="off">
                                            <label class="btn btn-outline-primary text-dark px-3 px-5" for="aa_mem_no">No</label>
                                        </div>
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label for="aa_no" :class="'form-label '+(checkboxes.cb_aa_member === 1 ? 'required' : null)">AA membership number</label>
                                        <input v-model="application.aa_no" type="text" :class="errorClass('aa_no')" id="aa_no" maxlength="20" :required="checkboxes.cb_aa_member === 1">
                                        <span v-if="hasError('aa_no')" class="invalid-feedback" role="alert" v-html="getError('aa_no')"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card shadow-sm mt-4">
                            <div class="card-header bg-dark text-white">
                                Address Information
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="mb-3 col-md-6">
                                        <label for="addr_line_1" class="form-label required">Address Line 1</label>
                                        <input v-model="application.addr_line_1" type="text" :class="errorClass('addr_line_1')" id="addr_line_1" required>
                                        <span v-if="hasError('addr_line_1')" class="invalid-feedback" role="alert" v-html="getError('addr_line_1')"/>
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label for="addr_line_2" class="form-label">Address Line 2</label>
                                        <input v-model="application.addr_line_2" type="text" :class="errorClass('addr_line_2')" id="addr_line_2">
                                        <span v-if="hasError('addr_line_2')" class="invalid-feedback" role="alert" v-html="getError('addr_line_2')"/>
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label for="addr_suburb" class="form-label required">Suburb</label>
                                        <input v-model="application.addr_suburb" type="text" :class="errorClass('addr_suburb')" id="addr_suburb" required>
                                        <span v-if="hasError('addr_suburb')" class="invalid-feedback" role="alert" v-html="getError('addr_suburb')"/>
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label for="addr_code" class="form-label required">Area Code</label>
                                        <input v-model="application.addr_code" type="text" pattern="[0-9]*" :class="errorClass('addr_code')" id="addr_code" minlength="4" maxlength="4" required>
                                        <span v-if="hasError('addr_code')" class="invalid-feedback" role="alert" v-html="getError('addr_code')"/>
                                    </div>
                                    <div class="mt-4">
                                        <button type="button" class="btn btn-primary float-start" @click="stepBack()">
                                            <i class="fas fa-fw fa-arrow-left"></i> Back
                                        </button>
                                        <button type="submit" class="btn btn-primary float-end">
                                            Continue <i class="fas fa-fw fa-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="loading" class="card-footer bg-primary text-center">
                                <strong class="h4">Loading...</strong>
                                <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-else-if="step === 7" class="card shadow-sm">
                    <div class="card-header bg-dark text-white" v-text="steps[step]"/>
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="saveStep7PolicyInfo()">
                            <div class="row">
                                <div class="col-12">
                                    <p class="fw-bolder small font-mont">WAITING PERIODS &amp; EXCESSES</p>
                                    <div class="table-responsive">
                                        <table class="table table-bordered border-1 border-dark">
                                            <thead>
                                                <tr class="bg-primary">
                                                    <th scope="col">Days from policy start date</th>
                                                    <th scope="col">Excess</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="fw-bold">0 - 30 days</td>
                                                    <td>Pay 100% of the claim from your own account.</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold">31 - 60 days</td>
                                                    <td>Pay 50% of the claim amount.</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold">61 - 89 days</td>
                                                    <td>Pay 25% of the claim amount.</td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-bold">90 days +</td>
                                                    <td>Pay R500 per claim. AA members do NOT pay excess after 90 days.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                        <div class="form-check ">
                                            <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_excess">
                                                I understand and accept the Excesses and Waiting Periods
                                            </label>
                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_excess" :true-value="1" :false-value="0" id="cb_excess" required>
                                            <span v-if="hasError('excess')" class="invalid-feedback" role="alert" v-html="getError('excess')"/>
                                        </div>
                                    </div>
                                    <div class="fw-light small font-mont">
                                        <p class="fw-bolder mt-5">VEHICLE SERVICE HISTORY</p>
                                        <p>I acknowledge that claims will only be considered upon presentation of my vehicle's service history. Since I do not possess a full service history, I commit to having a major service conducted on my vehicle within 30 days.</p>
                                    </div>
                                    <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                        <div class="form-check ">
                                            <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_servicing">
                                                I understand and accept the above condition
                                            </label>
                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_servicing" :true-value="1" :false-value="0" id="cb_servicing" required>
                                            <span v-if="hasError('servicing')" class="invalid-feedback" role="alert" v-html="getError('servicing')"/>
                                        </div>
                                    </div>
                                    <div class="fw-light small font-mont">
                                        <p class="fw-bolder mt-5">EXCLUSIONS</p>
                                        <p>The section titled
                                            <i>D. We Do Not Cover</i> (Pages 10 + 11) in the policy wording details what is not covered by an AA Warranties policy. You must read and understand these Exclusions before you complete this application.
                                        </p>
                                        <p>
                                            <a class="text-black" href="/legal/AA_Warranties_Monthly_Policy_Wording.pdf" target="_blank">Click here</a> to view the policy wording. (Exclusions: Page 10 + 11)
                                        </p>
                                    </div>
                                    <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                        <div class="form-check ">
                                            <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_exclusions">
                                                I understand and accept the Exclusions
                                            </label>
                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_exclusions" :true-value="1" :false-value="0" id="cb_exclusions" required>
                                            <span v-if="hasError('exclusions')" class="invalid-feedback" role="alert" v-html="getError('exclusions')"/>
                                        </div>
                                    </div>
                                    <div class="mt-4 accordion" id="declarationsAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="appDeclarations">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                    APPLICANT DECLARATIONS <span class="ms-1 text-danger">*</span>
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse show text-black" aria-labelledby="appDeclarations" data-bs-parent="#declarationsAccordion">
                                                <div class="accordion-body">
                                                    <div class="small fw-light font-mont">
                                                        <p>All the information in this proposal is true and complete and will form the basis of the contract between me and the insurer.</p>
                                                        <p>Misrepresentation will result in you having no cover and no claim will be able to be paid in terms thereof.</p>
                                                        <p>
                                                            I do not know of any material facts that should be communicated, even through specific questions about them have not
                                                            been asked. This means that Lombard Insurance Company Limited has been made aware of all important information
                                                            and if any information is not correct this policy will be voided and all premiums paid by me will be lost.
                                                        </p>
                                                        <p>
                                                            Lombard Insurance Company Limited may share my insurance information either directly or through a database for
                                                            insurers to gather industry statistics, to improve the quality of risk assessment and to prevent fraud.
                                                        </p>
                                                        <p>
                                                            Lombard Insurance Company Limited may share my personal information to their service providers who assist us in
                                                            managing this policy and relationship with me. This will always be done as permitted by the relevant privacy legislation.
                                                        </p>
                                                        <p>I understand that this insurance will not commence until this proposal has been accepted by the insurer.</p>
                                                        <p>
                                                            Lombard Insurance Company Limited, or an institution that Lombard nominate, may withdraw the premiums from mv
                                                            account. This request will be in force until cancelled in writing.
                                                        </p>
                                                    </div>
                                                    <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                                        <div class="form-check ">
                                                            <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_applicant_dec">
                                                                I understand and accept the declaration above
                                                            </label>
                                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_applicant_dec" :true-value="1" :false-value="0" id="cb_applicant_dec" required>
                                                            <span v-if="hasError('applicant_dec')" class="invalid-feedback" role="alert" v-html="getError('applicant_dec')"/>
                                                        </div>
                                                    </div>
                                                    <div class="mt-3">
                                                        <button type="button" class="btn btn-link my-3" data-bs-toggle="modal" data-bs-target="#sdnModal">
                                                            Statutory Disclosure Notice
                                                        </button>
                                                        <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                                            <div class="form-check ">
                                                                <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_statutory_disc">
                                                                    I also acknowledge the Statutory Disclosure notice
                                                                </label>
                                                                <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_statutory_disc" :true-value="1" :false-value="0" id="cb_statutory_disc" required>
                                                                <span v-if="hasError('statutory_disc')" class="invalid-feedback" role="alert" v-html="getError('statutory_disc')"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="sdnModal" tabindex="-1" aria-labelledby="sdnModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-dialog-centered modal-lg">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="sdnModalLabel">Statutory Disclosure Notice</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body small">
                                                    <p>
                                                        <strong>Legally speaking, you have a right to certain information about the AA Warranties insurance plan and the parties who work together in providing you with insurance services.</strong>
                                                    </p>
                                                    <p>
                                                        <strong>This notice provides information about the Intermediary who sold this policy to you as well as its Compliance Officer.</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Details of the Intermediary who sold this policy to you:</strong>
                                                    </p>
                                                    <div>
                                                        <strong>Trade name:</strong> AA InsuranceSupermarket<br>
                                                        <strong>Business name:</strong> InsuranceSupermarket Insurance Brokers (Pty) Limited<br>
                                                        <strong>Registration number:</strong> 2012/044142/07<br>
                                                        <strong>FAIS License number:</strong> 43986<br>
                                                        <strong>Physical address:</strong> The Braes Office Park, AMA Africa House, 193 Bryanston Drive, Bryanston, 2021<br>
                                                        <strong>Telephone:</strong> +27 (0)11 037 9000<br>
                                                        <strong>Fax:</strong> +27(0)86 760 6462<br>
                                                        <strong>Email:</strong> info@aainsurance.co.za<br>
                                                        <strong>Complaints:</strong> complaints@aainsurance.co.za
                                                    </div>
                                                    <p class="mt-4">
                                                        <strong>AA Insurance Supermarket Insurance Brokers:</strong></p>
                                                    <ul>
                                                        <li>Is an Authorised Financial Services Provider</li>
                                                        <li>Is licensed to market short term insurance personal lines policies</li>
                                                        <li>Is an independent Intermediary who has a contractual relationship Lombard Insurance Company Limited (the product provider)</li>
                                                        <li>Has a contractual relationship with other product suppliers as well</li>
                                                        <li>Has a conflict of interest policy (available on request)</li>
                                                        <li>Has a complaints resolution procedure (available on request</li>
                                                        <li>Holds professional indemnity insurance cover</li>
                                                        <li>Is not required to hold an IGF Guarantee</li>
                                                        <li>Receives commission as detailed on your policy schedule</li>
                                                        <li>Does not receive more than 30% of its income from any one insurer</li>
                                                        <li>Does not own more than 10% of the issued shares directly or indirectly in any other Financial Product provider</li>
                                                    </ul>
                                                    <p>
                                                        <strong>You can contact the compliance officer if you believe the policy was not sold accurately or fairly:</strong>
                                                    </p>
                                                    <div>
                                                        <strong>Compliance Officer:</strong> Moonstone Compliance (Pty) Ltd<br>
                                                        <strong>Postal address:</strong> PO Box 12662, Die Boord, Stellenbosch, 7613<br>
                                                        <strong>Telephone:</strong> 021 883 8000<br>
                                                        <strong>Fax:</strong> 021 880 1953<br>
                                                        <strong>Email:</strong> SGerald@moonstonecompliance.co.za
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <button type="button" class="btn btn-primary float-start" @click="stepBack()">
                                            <i class="fas fa-fw fa-arrow-left"></i> Back
                                        </button>
                                        <button type="submit" class="btn btn-primary float-end" :disabled="!checkboxes.cb_excess || !checkboxes.cb_servicing || !checkboxes.cb_exclusions || !checkboxes.cb_applicant_dec || !checkboxes.cb_statutory_disc">
                                            Continue <i class="fas fa-fw fa-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="loading" class="card-footer bg-primary text-center">
                        <strong class="h4">Loading...</strong>
                        <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                    </div>
                </div>
                <div v-else-if="step === 8" class="card shadow-sm">
                    <div class="card-header bg-dark text-white" v-text="steps[step]"/>
                    <div class="card-body">
                        <form class="needs-validation" @submit.prevent="saveStep8PaymentInfo()">
                            <div class="row">
                                <div class="mb-3 col-md-6">
                                    <label for="bank_id" class="form-label required">Bank</label>
                                    <div class="input-group mb-3">
                                        <select v-model="bd.bank_id" :class="errorClass('bank_id', 'form-select')" id="bank_id" required>
                                            <option :value="null">Select bank</option>
                                            <option v-for="(value, key) in options.banks" :value="key">{{ value.name }}</option>
                                        </select>
                                        <span v-if="hasError('bank_id')" class="invalid-feedback" role="alert" v-html="getError('bank_id')"/>
                                    </div>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="branch_code" class="form-label">Branch Code</label>
                                    <input v-model="bd.branch_code" type="text" :class="errorClass('branch_code')" id="branch_code" readonly required>
                                    <span v-if="hasError('branch_code')" class="invalid-feedback" role="alert" v-html="getError('branch_code')"/>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="acc_holder" class="form-label required">Account Holder</label>
                                    <input v-model="bd.acc_holder" type="text" :class="errorClass('acc_holder')" id="acc_holder" required>
                                    <span v-if="hasError('acc_holder')" class="invalid-feedback" role="alert" v-html="getError('acc_holder')"/>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="acc_no" class="form-label required">Account Number</label>
                                    <input v-model="bd.acc_no" type="text" pattern="[0-9]*" :class="errorClass('acc_no')" id="acc_no" required>
                                    <span v-if="hasError('acc_no')" class="invalid-feedback" role="alert" v-html="getError('acc_no')"/>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="acc_type" class="form-label required">Account Type</label>
                                    <div class="input-group mb-3">
                                        <select v-model="bd.acc_type" :class="errorClass('acc_type', 'form-select')" id="acc_type" required>
                                            <option :value="null">Select account type</option>
                                            <option v-for="acc_type in options.acc_types" :value="acc_type">{{ acc_type }}</option>
                                        </select>
                                        <span v-if="hasError('acc_type')" class="invalid-feedback" role="alert" v-html="getError('acc_type')"/>
                                    </div>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="debit_day" class="form-label required">Debit order day</label>
                                    <div class="input-group mb-3">
                                        <select v-model="bd.debit_day" :class="errorClass('debit_day', 'form-select')" id="debit_day" required>
                                            <option :value="null">Select your debit day</option>
                                            <option v-for="(debit_day,key) in options.debit_days" :value="key">{{ debit_day }}</option>
                                        </select>
                                        <span v-if="hasError('debit_day')" class="invalid-feedback" role="alert" v-html="getError('debit_day')"/>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <div class="fw-light font-mont small">
                                        <p class="fw-bolder">DEBIT ORDER NOTICE</p>
                                        <p>By completing this process you hereby authorise the insurer to draw against this account all amounts due in terms of this application. This authorisation is to remain in force until terminated by the Insurer or yourself.</p>
                                    </div>
                                    <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                        <div class="form-check ">
                                            <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_debit_order">
                                                I understand and accept this
                                            </label>
                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_debit_order" :true-value="1" :false-value="0" id="cb_debit_order" required>
                                            <span v-if="hasError('debit_order')" class="invalid-feedback" role="alert" v-html="getError('debit_order')"/>
                                        </div>
                                    </div>
                                    <div class="mt-5 fw-light font-mont small">
                                        <p class="fw-bolder">PREMIUM PAYMENTS</p>
                                        <p class="fw-bold">1. What must You pay?</p>
                                        <p>
                                            a. This is a monthly policy. This means that Your policy is automatically renewed for another month every time You pay the monthly premium.<br>
                                            b. The premium payer must pay the premium listed in the policy schedule. If You are not the premium payer and the premium payer stops paying the premiums it remains Your responsibility to pay the premiums for cover to continue.
                                        </p>
                                        <p class="fw-bold">2. When must You pay?</p>
                                        <p>
                                            a. For the policy to start, the premium payer must pay the first month's premium on the agreed premium debit date. If We do not receive Your first premium, We will attempt to debit Your account again on the premium debit date the following month with the premium amount due. If We do not receive the premium on the next month, the policy will not start.<br>
                                            b. For the policy to renew each month, the premium is due on the premium debit date. We will allow a 1-month grace period (extra time) from the premium debit date for You to pay Your premium. If We do not receive Your first premium, We will attempt to debit Your account again on the premium debit date the following month with the premium due.<br>
                                            c. If the premium debit date falls on a weekend or public holiday, the premium payer's bank account will be debited on the first working day before or after the weekend or public holiday.
                                        </p>
                                        <p class="fw-bold">3. What will happen if You don't pay?</p>
                                        <p>
                                            a. If the debit order fails because of insufficient funds, We may attempt to debit Your account again during the month and if not successful, We will debit Your account with the premium on Your selected debit order date the next month Your premium is due.<br>
                                            b. If We do not receive the payment after Our re-attempt as explained above in 3.a, the policy will end on the last day of the month for which a premium was received. We do not have to reinstate Your policy.<br>
                                            c. If You stop the debit order directly with Your bank, We will allow a 1-month grace period to pay the premium. If no premium is received after the grace period, the policy will end on the last day of the month for which a premium was received. If You want to continue with Your policy, You need to contact Us.<br>
                                            d. We will honour a claim that arises during the grace period if it is a valid claim. The unpaid premium will be deducted from the approved benefit amount
                                        </p>
                                    </div>
                                    <p class="fw-bold">I hereby confirm acceptance of the quotation received and confirm that I agree to taking out the AA Warranties mechanical breakdown insurance plan.</p>
                                    <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                        <div class="form-check ">
                                            <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_payments">
                                                I understand and accept this
                                            </label>
                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_payments" :true-value="1" :false-value="0" id="cb_payments" required>
                                            <span v-if="hasError('payments')" class="invalid-feedback" role="alert" v-html="getError('payments')"/>
                                        </div>
                                    </div>
                                    <p class="fw-bold mt-5">I give my consent to receive direct marketing communication from the specified data partners in relation to relevant goods or services to be marketed by means of electronic communications.</p>
                                    <div class="border border-2 rounded-pill border-dark py-2 px-3">
                                        <div class="form-check ">
                                            <label class="form-check-label small font-helv fw-bold mx-2" for="cb_consent">
                                                I understand and accept this
                                            </label>
                                            <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_consent" :true-value="1" :false-value="0" id="cb_consent">
                                            <span v-if="hasError('consent')" class="invalid-feedback" role="alert" v-html="getError('consent')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <button type="button" class="btn btn-primary float-start" @click="stepBack()">
                                        <i class="fas fa-fw fa-arrow-left"></i> Back
                                    </button>
                                    <button type="submit" class="btn btn-primary float-end" :disabled="!checkboxes.cb_debit_order || !checkboxes.cb_payments || loading">
                                        <i class="fas fa-fw fa-check"></i> Finish
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="loading" class="card-footer bg-primary text-center">
                        <strong class="h4">Loading...</strong>
                        <div class="spinner-border float-end" role="status" aria-hidden="true"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import flatpickr from 'flatpickr';
export default {
    props: ['aid', 'new', 'stp', 'hp'],
    components: {},
    data() {
        return {
            new_session: this.new === 'Y',
            slug: this.aid,
            step: Number(this.stp),
            steps: {
                1: 'Basic Information',
                2: 'Vehicle Information',
                3: 'Available Cover Options',
                4: 'Additional Information',
                5: 'Quote Summary',
                6: 'Personal Information',
                7: 'Policy Information',
                8: 'Payment Information',
            },
            any_number: 1,
            options: {
                use_types: [],
                underwriting: {},
                years: [],
                makes: {},
                cover_plans: {},
                banks: {},
                debit_days: {},
                acc_types: []
            },
            application: {
                step: Number(this.stp),
                slug: this.aid,
            },
            bd: {
                bank_id: null,
                acc_type: null,
                debit_day: null
            },
            ba: null,
            vehicle: {
                use_type: null,
                year: null,
                make: null,
                model: null
            },
            vehicles: [],
            vehicle_models: null,
            vehicle_cover: null,
            cover_options: null,
            total_covers: 0,
            cover_savings: 0,
            checkboxes: {
                cb_privacy: 0,
                cb_aa_member: 0,
                cb_applicant_dec: 0,
                cb_statutory_disc: 0,
                cb_excess: 0,
                cb_servicing: 0,
                cb_exclusions: 0,
                cb_debit_order: 0,
                cb_payments: 0,
                cb_consent: 0
            },
            errors: null,
            toggle_plans: true,
            loading: true,
            api_url: null,
            contact_msg: null,
            pickr_config: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
                defaultDate: null,
                allowInput: false,
            },
        }
    },
    computed: {
        //
    },
    mounted() {
        this.api_url = '/api/quote/' + this.slug;

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response.status === 302 && error.response.data && error.response.data.data.location !== undefined) {
                window.location = error.response.data.data.location;
            } else if (error.response.status === 419) {
                window.location = '/quote';
            } else if (error.response.status >= 500) {
                window.location = '/quote/system-offline';
            } else {
                return Promise.reject(error);
            }
        });

        axios.get('/api/quote/options').then((response) => {
            this.options = response.data.data;
            this.loading = false;

            if (!this.new_session) {
                this.getApplication();
                this.getVehicleModels();
            }

            this.animateRadialProgress(this.step);
        });
    },
    updated() {
        flatpickr("#dob", this.pickr_config);
    },
    watch: {
        'vehicle.model_id'(_value) {
            this.getVehicleModels();
        },
        'application.aa_no'(_value) {
            this.checkboxes.cb_aa_member = _value ? 1 : 0;
        },
        'checkboxes.cb_aa_member'(_value) {
            if (!_value) {
                this.application.aa_no = null;
            }
        },
        'bd.bank_id'(_value) {
            this.bd.branch_code = this.options.banks[_value].branch_code;
        },
        step(_value) {
            this.animateRadialProgress(_value);
            if (_value === 5 || _value === 8) {
                if (!this.vehicles.vehicles) {
                    this.getAllApplicationVehicles();
                }
            }
            this.scrollToTop();
        }
    },
    methods: {
        /**
         * STEP MANAGEMENT / API CALLS #############################################################################
         */
        saveStep1BasicInfo() {
            this.updateBasicInfo();
        },
        saveStep2VehicleInfo() {
            this.updateVehicleInfo();
        },
        saveStep3VehicleCoverInfo() {
            this.updateVehicleCoverInfo();
        },
        saveStep4UnderwritingInfo() {
            this.updateUnderwritingInfo();
        },
        saveStep5QuoteSummary() {
            this.updateQuoteSummaryInfo();
        },
        saveStep6PersonalInfo() {
            this.updatePersonalInfo();
        },
        saveStep7PolicyInfo() {
            this.updatePolicyInfo();
        },
        saveStep8PaymentInfo() {
            this.updatePaymentInfo();
        },
        stepBack() {
            // If user already has vehicles and clicks back
            if( this.step === 2 && this.vehicle ) {
                this.gotoStep(5);
            } else {
                this.step--;
            }
        },
        /**
         * Step 1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111
         */
        getApplication() {
            this.loading = true;
            axios.get(this.api_url).then((response) => {
                this.updateDataFromResponse(response.data.data);
            }).catch((error) => {
                this.loading = false;
            });
        },
        updateBasicInfo() {
            this.loading = true;
            this.errors = null;

            axios.put(this.api_url + '/basic', this.application).then((response) => {
                this.updateDataFromResponse(response.data.data);
                this.sendGAEvent(1);
                this.loading = false;
                this.scrollToTop();
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            }).finally(() => {
                this.scrollToTop();
            });
        },
        /**
         * Step 2222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222
         */
        getVehicleModels(_clear_vehicle) {
            if (!this.vehicle.make || !this.vehicle.year) {
                return;
            }

            if (_clear_vehicle) {
                this.clearVehicleDetails();
            }

            this.loading = true;
            axios.get('/api/vehicles/models', {
                params: {
                    make: this.vehicle.make,
                    year: this.vehicle.year,
                }
            }).then((response) => {
                this.vehicle_models = response.data.data;
                this.loading = false;
            }).catch((error) => {
                this.vehicle_models = null;
                this.loading = false;
            });
        },
        getVehicleDetails() {
            this.loading = true;
            axios.get('/api/vehicles/model/' + this.vehicle.model_id).then((response) => {
                var vehicle = response.data.data;
                this.vehicle.model = vehicle.model;
                this.vehicle.mm_make = vehicle.mm_make;
                this.vehicle.mm_model = vehicle.mm_model;
                this.vehicle.mm_code = vehicle.mm_code;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
            });
        },
        clearVehicleDetails() {
            this.vehicle.model_id = null;
            this.vehicle.model = null;
            this.vehicle.mm_make = null;
            this.vehicle.mm_model = null;
            this.vehicle.mm_code = null;
        },
        updateVehicleInfo() {
            this.loading = true;
            this.errors = null;
            var api_url = this.vehicle.id
                ? (this.api_url + '/vehicle/' + this.vehicle.slug)
                : (this.api_url + '/vehicle');

            axios.put(api_url, this.vehicle).then((response) => {
                this.updateDataFromResponse(response.data.data);
                this.sendGAEvent(2);
                this.scrollToTop();
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            }).finally(() => {
                this.scrollToTop();
            });
        },
        /**
         * Step 3333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333333
         */
        showPlan(_index) {
            // If we have more than 3 plans, only show first 3
            _index = Number(_index) + 1; // Simply for easy comparison
            if (this.total_covers <= 3) return true;
            if (!this.toggle_plans) return true;
            if (_index <= 3) return true;
        },
        selectVehicleCover(_cover_id) {
            this.loading = true;
            axios.put(this.api_url + '/vehicle/' + this.vehicle.slug + '/cover/' + _cover_id).then((response) => {
                this.updateDataFromResponse(response.data.data);
                //this.scrollToTop();
            }).catch((error) => {
                this.loading = false;
            }).finally(() => {
                //this.scrollToTop();
            });
        },
        updateVehicleCoverInfo() {
            this.loading = true;
            this.errors = null;

            axios.put(this.api_url + '/vehicle-cover', {
                cover_id: this.vehicle_cover.id,
            }).then((response) => {
                this.updateDataFromResponse(response.data.data);
                this.sendGAEvent(3);
                this.scrollToTop();
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            }).finally(() => {
                this.scrollToTop();
            });
        },
        /**
         * STEP 4444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444444
         */
        hasNullUnderwriting() {
            var keys = Object.keys(this.options.underwriting);
            for (const key of keys) {
                if (this.vehicle[key] === null) {
                    return true;
                }
            }

            return false;
        },
        hasFailedUnderwriting() {
            for (const [key, value] of Object.entries(this.options.underwriting)) {
                if (this.vehicle[key] === value.fails_on) {
                    return true;
                }
            }
        },
        showUnderwritingWarning(_selected, _fails) {
            // Extend failure options to allow Yes / No to show error only but not fail
            // 0 = Fails on No
            // 1 = Fails on Yes
            // 2 = Show error if No but don't fail
            // 3 = Show error if Yes but don't fail
            if(_fails > 1) _fails -= 2;
            if(_selected === _fails) return true;
        },
        updateUnderwritingInfo() {
            this.loading = true;
            this.errors = null;
            var v = this.vehicle;
            var request = {
                uw_high_value: v.uw_high_value,
                uw_code_3: v.uw_code_3,
                uw_sa_registerd: v.uw_sa_registerd,
                uw_multi_claimant: v.uw_multi_claimant,
                uw_modified: v.uw_modified,
                uw_serviced: v.uw_serviced,
                uw_existing_faults: v.uw_existing_faults,
                uw_imported: v.uw_imported,
                uw_exotic: v.uw_exotic,
            };

            axios.put(this.api_url + '/underwriting', request).then((response) => {
                this.updateDataFromResponse(response.data.data);
                this.sendGAEvent(4);
                this.scrollToTop();
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            }).finally(() => {
                this.scrollToTop();
            });
        },
        /**
         * STEP 5555555555555555555555555555555555555555555555555555555555555555555555555555555555555555555555555555
         */
        getAllApplicationVehicles() {
            this.loading = true;
            this.errors = null;

            axios.get(this.api_url + '/vehicles').then((response) => {
                this.vehicles = response.data.data.vehicles;
                this.cover_savings = response.data.data.saving;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
            });
        },
        addVehicle() {
            this.loading = true;
            axios.post(this.api_url + '/vehicles').then((response) => {
                window.location = '/quote';
            }).catch((error) => {
                this.loading = false;
            });
        },
        editVehicle(_vehicle) {
            this.loading = true;
            axios.get(this.api_url + '/vehicles/' + _vehicle).then((response) => {
                window.location = '/quote';
            }).catch((error) => {
                this.loading = false;
            });
        },
        removeVehicle(_vehicle) {
            this.loading = true;
            axios.delete(this.api_url + '/vehicles/' + _vehicle).then((response) => {
                window.location = '/quote';
            }).catch((error) => {
                this.loading = false;
            });
        },
        updateQuoteSummaryInfo() {
            this.loading = true;
            axios.put(this.api_url + '/summary').then((response) => {
                this.updateDataFromResponse(response.data.data);
                this.sendGAEvent(5);
                this.scrollToTop();
            }).catch((error) => {
                this.loading = false;
            }).finally(() => {
                this.scrollToTop();
            });
        },
        /**
         * STEP 6666666666666666666666666666666666666666666666666666666666666666666666666666666666666666666666666666
         */
        updatePersonalInfo() {
            this.loading = true;
            this.errors = null;
            var a = this.application;
            var request = {
                id_no: this.application.id_no,
                aa_member: this.checkboxes.cb_aa_member,
                aa_no: (this.checkboxes.cb_aa_member ? a.aa_no : null),
                addr_line_1: this.application.addr_line_1,
                addr_line_2: this.application.addr_line_2,
                addr_suburb: this.application.addr_suburb,
                addr_code: this.application.addr_code,
            };

            axios.put(this.api_url + '/personal', request).then((response) => {
                this.updateDataFromResponse(response.data.data);
                this.sendGAEvent(6);
                this.scrollToTop();
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            }).finally(() => {
                this.scrollToTop();
            });
        },
        /**
         * STEP 7777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777777
         */
        updatePolicyInfo() {
            this.loading = true;
            this.errors = null;
            var request = {
                excess: this.checkboxes.cb_excess,
                servicing: this.checkboxes.cb_servicing,
                exclusions: this.checkboxes.cb_exclusions,
            };

            axios.put(this.api_url + '/policy', request).then((response) => {
                this.updateDataFromResponse(response.data.data);
                this.sendGAEvent(7);
                this.scrollToTop();
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            }).finally(() => {
                this.scrollToTop();
            });
        },
        /**
         * STEP 8888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888
         */
        getBnkDetails() {
            this.loading = true;
            this.errors = null;

            axios.post(this.ba.url, {sec: this.ba.sec}).then((response) => {
                this.bd = response.data.data;
                this.ba = this.bd.ba;
                this.loading = false;
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            });
        },
        updatePaymentInfo() {
            this.loading = true;
            this.errors = null;
            this.sendGAEvent(8, this.application.premium_total);

            var request = this.bd;
            request.cb_consent = this.checkboxes.cb_consent;
            request.sec = this.ba.sec;

            axios.put(this.ba.url, request).then((response) => {
                // this.loading = false;
                window.location = response.data.data.url;
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.loading = false;
            });
        },
        /**
         * HELPER FUNCTIONS ########################################################################################
         */
        updateDataFromResponse(_response) {
            this.application = _response.application;
            this.vehicle = _response.vehicle;
            this.vehicle_cover = _response.vehicle_cover;
            this.cover_options = _response.cover_options;
            if (this.cover_options !== null) {
                this.total_covers = Object.keys(this.cover_options).length;
            }
            this.step = this.application.step;
            this.loading = false;

            if (this.application.prequalifier && this.step === 3) {
                // Set a default vehicle cover if none selected during pre-qualifier
                if (this.cover_options !== null && this.vehicle_cover == null) {
                    this.vehicle_cover = this.cover_options[0];
                }
                // Track Pre-qualifier event
                try {
                    dataLayer.push({'event': `Warranty_check`});
                } catch (e) {
                    console.log('Failed to send GA event!');
                }
            }

            if (this.step === 5 || this.step === 8) {
                if (!this.vehicles.vehicles) {
                    this.getAllApplicationVehicles();
                }
            }
            if (this.step === 8) {
                if (_response.ba) {
                    this.ba = _response.ba;
                    this.getBnkDetails();
                }
            }
        },
        hasError(_key) {
            return this.errors !== null && this.errors.hasOwnProperty(_key);
        },
        getError(_key) {
            return '<strong>' + this.errors[_key][0] + '</strong>';
        },
        errorClass(_key, _additional = '') {
            var el_class = 'form-control ' + _additional;
            return this.hasError(_key) ? (el_class + ' is-invalid') : el_class;
        },
        animateRadialProgress(_step) {
            var sp = $('#step-progress');
            var percent = (_step / 8 * 100);
            var radius = sp.find($('circle.complete')).attr('r');
            var circumference = 2 * Math.PI * radius;
            var strokeDashOffset = circumference - ((percent * circumference) / 100);
            sp.find($('circle.complete')).animate({'stroke-dashoffset': strokeDashOffset}, 1250);
        },
        sendGAEvent(_step, _premium = null) {
            // Google Analytics
            try {
                if (_premium) {
                    dataLayer.push({'event': `step-${_step}-complete`, 'conversionValue': _premium});
                } else {
                    dataLayer.push({'event': `step-${_step}-complete`});
                }
            } catch (e) {
                console.log('Failed to send GA event!');
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        toggleInfoBox(_key) {
            this.options.underwriting[_key].toggled = !this.options.underwriting[_key].toggled;
        },
        gotoStep(_step) {
            this.loading = true;

            axios.put(this.api_url + '/step', { step: _step }).then((response) => {
                window.location = '/quote';
            }).catch((error) => {
                this.loading = false;
            });
        }
    }
}
</script>
