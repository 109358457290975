<template>
    <div class="modal fade" id="contactForm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="contactFormLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Call me back</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form class="needs-validation" @submit.prevent="submitContact()">
                    <div class="modal-body">
                        <div v-if="success" class="alert alert-success mt-3">
                            <strong>Thank you for your message.</strong> We will get back to you shortly.
                        </div>
                        <div v-if="failed" class="alert alert-danger mt-3">
                            <span v-html="failed"></span>
                        </div>
                        <div class="row">
                            <input type="hidden" name="slug" v-model="contact.slug">
                            <div v-if="hpot.enabled" :name="`${hpot.nameFieldName}_wrap`" style="display:none;">
                                <input type="text" v-model="contact[hpot.nameFieldName]" :name="hpot.nameFieldName" :id="hpot.nameFieldName" />
                                <input type="text" v-model="contact[hpot.validFromFieldName]" :name="hpot.validFromFieldName" />
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="first_name" class="form-label required">First Name</label>
                                <input id="first_name" v-model="contact.first_name" type="text" class="form-control" :class="errorClass('first_name')" minlength="2" placeholder="Jane" autocomplete="given-name" required>
                                <span v-if="hasError('first_name')" class="invalid-feedback" role="alert" v-html="getError('first_name')"/>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="last_name" class="form-label required">Last Name</label>
                                <input id="last_name" v-model="contact.last_name" type="text" class="form-control" :class="errorClass('last_name')" minlength="2" placeholder="Doe" autocomplete="family-name" required>
                                <span v-if="hasError('last_name')" class="invalid-feedback" role="alert" v-html="getError('last_name')"/>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="email" class="form-label required">Email Address</label>
                                <input id="email" v-model="contact.email" type="email" class="form-control" :class="errorClass('email')" minlength="6" placeholder="jane.doe@example.com" autocomplete="email" required>
                                <span v-if="hasError('email')" class="invalid-feedback" role="alert" v-html="getError('email')"/>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="mobile" class="form-label required">Cellphone Number</label>
                                <input id="mobile" v-model="contact.mobile" type="text" class="form-control" :class="errorClass('mobile')" minlength="10" maxlength="10" placeholder="0820001234" autocomplete="tel-local" required>
                                <span v-if="hasError('mobile')" class="invalid-feedback" role="alert" v-html="getError('mobile')"/>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div v-if="loading" class="spinner-border spinner-border-sm float-end me-2" role="status" aria-hidden="true"></div>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            <i class="fas fa-fw fa-circle-xmark me-1"></i> Cancel
                        </button>
                        <button type="submit" class="btn btn-primary" :disabled="loading">
                            <i class="fas fa-fw fa-paper-plane me-1"></i> Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['hp', 'aid', 'fn', 'ln', 'em', 'pn', 'msg'],
    components: {},
    data() {
        return {
            hpot: JSON.parse(this.hp),
            contact: {
                slug: this.aid ?? null,
                first_name: this.fn ?? null,
                last_name: this.ln ?? null,
                email: this.em ?? null,
                mobile: this.pn ?? null,
                message: this.msg ?? 'Please contact me.',
            },
            errors: null,
            failed: false,
            success: false,
            loading: false,
        }
    },
    mounted() {
        // Honeypot
        this.contact[this.hpot.nameFieldName] = '';
        this.contact[this.hpot.validFromFieldName] = this.hpot.encryptedValidFrom;
    },
    methods: {
        submitContact() {
            this.loading = true;
            this.errors = null;
            this.failed = false;

            axios.post('/api/contact', this.contact).then((response) => {
                this.contact.message = null;
                this.loading = false;
                this.success = true;
            }).catch((error) => {
                this.errors = error.response.data.data;
                this.failed = error.response.data.message;
                this.loading = false;
            });
        },
        hasError(_key) {
            return this.errors !== null && this.errors.hasOwnProperty(_key);
        },
        getError(_key) {
            return '<strong>' + this.errors[_key][0] + '</strong>';
        },
        errorClass(_key, _additional = '') {
            var el_class = 'form-control ' + _additional;
            return this.hasError(_key) ? (el_class + ' is-invalid') : el_class;
        },
    }
}
</script>
