/// CSS
import '../sass/guest.scss';

/// Packages
import './bootstrap';
import '@fortawesome/fontawesome-free';
//import 'sweetalert';
//import 'flatpickr';
//import 'moment';

/// Custom
import './guest/custom';

/// Vue Components
import { createApp } from 'vue/dist/vue.esm-bundler';
import ApplicationSteps from './guest/components/ApplicationSteps.vue';
import PreQualifyCovers from './guest/components/PreQualifyCovers.vue';
import ContactUsModal from './guest/components/ContactUsModal.vue';
import CompactApplication from './guest/components/CompactApplication.vue';
import ExitModal from './guest/components/ExitModal.vue';
//import ExampleComponent from './guest/components/ExampleComponent.vue';

const app = createApp({});
app.component('quote-app', ApplicationSteps);
app.component('pre-qualify', PreQualifyCovers);
app.component('contact-us-modal', ContactUsModal);
app.component('compact-application', CompactApplication);
app.component('exit-modal', ExitModal);
//app.component('example', ExampleComponent);

app.mount('#app');
