<template>
    <div v-if="success" class="alert alert-success mt-3">
        <strong>Thank you for your message.</strong> We will get back to you shortly.
    </div>
    <div v-if="failed" class="alert alert-danger mt-3">
        <strong>Sorry there was a problem.</strong> Please try again later.
    </div>
    <form class="needs-validation" @submit.prevent="" v-if="!success">
        <div class="row">
            <div class="mb-3 col-md-6 d-none">
                <label for="company" class="form-label required">Company</label>
                <input name="company" type="text" class="form-control" id="company">
            </div>
            <div class="mb-3 col-md-6">
                <label for="first_name" class="form-label required text-white">First Name</label>
                <input v-model="application.first_name" type="text" :class="errorClass('first_name')" id="first_name"
                       name="first_name" required>
                <span v-if="hasError('first_name')" class="invalid-feedback" role="alert"
                      v-html="getError('first_name')"/>
            </div>
            <div class="mb-3 col-md-6">
                <label for="last_name" class="form-label required text-white">Last Name</label>
                <input v-model="application.last_name" type="text" :class="errorClass('last_name')" id="last_name"
                       name="last_name" required>
                <span v-if="hasError('last_name')" class="invalid-feedback" role="alert"
                      v-html="getError('last_name')"/>
            </div>
            <div class="mb-3 col-md-6">
                <label for="email" class="form-label required text-white">Email Address</label>
                <input v-model="application.email" type="email" :class="errorClass('email')" id="email" name="email"
                       required>
                <span v-if="hasError('email')" class="invalid-feedback" role="alert" v-html="getError('email')"/>
            </div>
            <div class="mb-3 col-md-6">
                <label for="mobile" class="form-label required text-white">Cell Number</label>
                <input v-model="application.mobile" type="text" pattern="[0-9]*" :class="errorClass('mobile')"
                       id="mobile" name="mobile" minlength="10" maxlength="10" required>
                <span v-if="hasError('mobile')" class="invalid-feedback" role="alert" v-html="getError('mobile')"/>
            </div>
            <div class="col-12">
                <label for="year" class="form-label required text-white fw-bold">Vehicle details</label>
            </div>
            <div class="mb-3 col-md-5 col-xl-3">
                <select v-model="vehicle.year" :class="errorClass('year', 'form-select')" name="year" id="year"
                        @change="getVehicleModels(true)" required>
                    <option :value="null" disabled>Year</option>
                    <option v-for="year in options.years" :value="year">{{ year }}</option>
                </select>
                <span v-if="hasError('year')" class="invalid-feedback" role="alert" v-html="getError('year')"/>
            </div>
            <div class="mb-3 col-md-7 col-xl-4">
                <select v-model="vehicle.make" :class="errorClass('make', 'form-select')" name="make" id="make"
                        @change="getVehicleModels(true)" required>
                    <option :value="null" disabled>Make</option>
                    <option v-for="make in options.makes" :value="make">{{ make }}</option>
                </select>
                <span v-if="hasError('make')" class="invalid-feedback" role="alert" v-html="getError('make')"/>
            </div>
            <div class="mb-3 col-md-12 col-xl-5">
                <select v-model="vehicle.model_id" :class="errorClass('model_id', 'form-select')" name="model_id"
                        id="model_id" :disabled="vehicle_models === null" required>
                    <option :value="null" disabled>Model</option>
                    <option v-for="(model,id) in vehicle_models" :value="id"
                            :selected="vehicle.model_id && vehicle.model_id == id">{{ model }}
                    </option>
                </select>
                <span v-if="hasError('model_id')" class="invalid-feedback" role="alert" v-html="getError('model_id')"/>
            </div>
            <div class="col-md-8">
                <div class="py-2 bg-white">
                    <div class="form-check">
                        <label class="form-check-label required small font-helv fw-bold mx-2" for="cb_privacy">
                            I have read and accept the
                            <a class="text-decoration-none" href="/privacy-policy" target="_blank">Privacy Policy</a>
                        </label>
                        <input class="form-check-input" type="checkbox" v-model="checkboxes.cb_privacy" :true-value="1"
                               :false-value="0" id="cb_privacy" required>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div v-if="loading" class="pt-2">
                    <span class="small float-end sr-only">Loading...</span>
                    <div class="spinner-border spinner-border-sm float-end me-2" role="status" aria-hidden="true"></div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col" v-show="showCallback()">
                <button type="submit" class="btn btn-secondary btn-lg m-2 ms-0 text-white"
                        :disabled="!vehicle.model_id || !checkboxes.cb_privacy || loading"
                        v-on:click="submitApp(true)">
                    GET A CALLBACK
                </button>
            </div>
            <div class="col">
                <button type="submit" class="btn btn-primary btn-lg m-2 ms-0"
                        :disabled="!vehicle.model_id || !checkboxes.cb_privacy || loading"
                        v-on:click="submitApp(false)">
                    BUY ONLINE
                </button>
                <div class="discount">
                    <div class="arrow"><img src="/images/discount-arrow.svg" alt=""></div>
                    <div class="text"><img src="/images/discount.svg" alt="Includes 10% Online Discount"></div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import moment from 'moment';

export default {
    data() {
        return {
            options: {
                years: [],
                makes: {},
            },
            application: {
                step: 2,
            },
            checkboxes: {
                cb_privacy: 0,
            },
            vehicle: {
                use_type: null,
                year: null,
                make: null,
                model_id: null,
                odometer: null,
                reg_no: null
            },
            vehicle_models: null,
            errors: null,
            failed: false,
            loading: true,
            success: false,
        }
    },
    mounted() {
        this.getOptions();
        this.getVehicleModels();
    },
    watch: {
        'vehicle.model_id'(_value) {
            this.getVehicleModels();
        },
    },
    methods: {
        submitApp(callback = false) {
            this.loading = true;
            this.errors = null;
            let data = {
                ...this.application,
                ...this.vehicle,
            };

            axios.post('/api/quote/compact', data).then((response) => {
                this.updateDataFromResponse(response.data.data);
                if (callback) {
                    // Send a callback request
                    this.sendGAEvent('Call_me_back');
                    this.submitContact();
                } else {
                    this.sendGAEvent('Start_online_application');
                    window.location = this.application.url;
                }
            }).catch((error) => {
                if(error.response.data.data) {
                    this.errors = error.response.data.data;
                } else {
                    this.failed = error.message;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        submitContact() {
            let contact = {
                source: 'campaign',
                slug: this.application.slug,
                first_name: this.application.first_name,
                last_name: this.application.last_name,
                email: this.application.email,
                mobile: this.application.mobile,
                message: "Please call me back regarding this application.",
            };
            axios.post('/api/contact', contact).then((response) => {
                this.success = true;
            }).catch((error) => {
                if(error.response.data.data) {
                    this.errors = error.response.data.data;
                } else {
                    this.failed = error.message;
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        getOptions() {
            axios.get('/api/quote/options').then((response) => {
                this.options = response.data.data;
                this.loading = false;
            });
        },
        getVehicleModels(_clear_vehicle) {
            if (!this.vehicle.make || !this.vehicle.year) {
                return;
            }

            if (_clear_vehicle) {
                this.clearVehicleDetails();
            }

            this.loading = true;
            axios.get('/api/vehicles/model_ids', {
                params: {
                    make: this.vehicle.make,
                    year: this.vehicle.year,
                }
            }).then((response) => {
                this.vehicle_models = response.data.data;
                this.loading = false;
            }).catch((error) => {
                this.vehicle_models = null;
                this.loading = false;
            });
        },
        clearVehicleDetails() {
            this.vehicle.model_id = null;
        },
        updateDataFromResponse(_response) {
            this.application = _response.application;
            this.vehicle = _response.vehicle;
            this.step = this.application.step;
        },
        hasError(_key) {
            return this.errors !== null && this.errors.hasOwnProperty(_key);
        },
        getError(_key) {
            return '<strong>' + this.errors[_key][0] + '</strong>';
        },
        errorClass(_key, _additional = '') {
            var el_class = 'form-control ' + _additional;
            return this.hasError(_key) ? (el_class + ' is-invalid') : el_class;
        },
        sendGAEvent(_event) {
            // Google Analytics
            try {
                dataLayer.push({'event': `${_event}`});
            } catch (e) {
                console.log('Failed to send GA event!');
            }
        },
        showCallback() {
            let time = moment();
            // Closed on all days before 07:00
            if(time.hour() < 7) return false;
            // Closed on weekdays after 17:00
            if(time.hour() >= 17) return false;
            // Closed on Saturdays after 14:00
            if(time.day() === 6 && time.hour() >= 14) return false;
            // Closed on Sundays
            return time.day() !== 0;
        }
    }
}
</script>
<style>
.discount {
    display: block;
    position: relative;
    width: 160px;
    text-align: right;
}

.discount .arrow {
    width: 12px;
    display: inline-block;
    margin-bottom: -30px;
    opacity: 1;
    -webkit-animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
    animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

.discount .text {
    max-width: 160px;
}

@-webkit-keyframes fadeTop {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-10%);
        transform: translateY(-10%)
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-10%);
        transform: translateY(-10%)
    }
    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
</style>
