<template>
    <div class="row">
        <div class="mb-3 col-md-2 d-none">
            <input type="text" class="form-control" id="company" placeholder="Company">
        </div>
        <div class="mb-3 col-md-2">
            <select v-model="vehicle.year" :class="errorClass('year', 'form-select')" name="year" id="year" @change="getVehicleModels(true)" required>
                <option :value="null" disabled>Year</option>
                <option v-for="year in options.years" :value="year">{{ year }}</option>
            </select>
            <span v-if="hasError('year')" class="invalid-feedback" role="alert" v-html="getError('year')"/>
        </div>
        <div class="mb-3 col-md-3">
            <select v-model="vehicle.make" :class="errorClass('make', 'form-select')" name="make" id="make" @change="getVehicleModels(true)" required>
                <option :value="null" disabled>Make</option>
                <option v-for="make in options.makes" :value="make">{{ make }}</option>
            </select>
            <span v-if="hasError('make')" class="invalid-feedback" role="alert" v-html="getError('make')"/>
        </div>
        <div class="mb-3 col-md-4">
            <select v-model="vehicle.model_id" :class="errorClass('model_id', 'form-select')" name="model_id" id="model_id" :disabled="vehicle_models === null" required>
                <option :value="null" disabled>Model</option>
                <option v-for="(model,id) in vehicle_models" :value="id" :selected="vehicle.model_id && vehicle.model_id == id">{{ model }}</option>
            </select>
            <span v-if="hasError('model_id')" class="invalid-feedback" role="alert" v-html="getError('model_id')"/>
        </div>
        <div class="mb-3 col-md-3">
            <input v-model="vehicle.odometer" type="text" pattern="[0-9]*" :class="errorClass('odometer')" name="odometer" id="odometer" minlength="1" maxlength="7" placeholder="Estimated odometer (in KM)" required>
            <span v-if="hasError('odometer')" class="invalid-feedback" role="alert" v-html="getError('odometer')"/>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <button type="submit" class="btn btn-primary btn-lg" :disabled="!vehicle.model_id || !vehicle.odometer">
                Check
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['err', 'old'],
    data() {
        return {
            options: {
                years: [],
                makes: {},
            },
            vehicle: {
                year: null,
                make: null,
                model_id: null,
                odometer: null,
            },
            vehicle_models: null,
            errors: JSON.parse(this.err),
            old_data: JSON.parse(this.old),
            loading: true,
        }
    },
    mounted() {
        this.getOptions();
        this.vehicle.year = this.old_data.year ?? null;
        this.vehicle.make = this.old_data.make ?? null;
        this.vehicle.model_id = this.old_data.model_id ?? null;
        this.vehicle.odometer = this.old_data.odometer ?? null;
        this.getVehicleModels();
    },
    watch: {
        'vehicle.model_id'(_value) {
            this.getVehicleModels();
        },
    },
    methods: {
        getOptions() {
            axios.get('/api/quote/options').then((response) => {
                this.options = response.data.data;
                this.loading = false;
            });
        },
        getVehicleModels(_clear_vehicle) {
            if (!this.vehicle.make || !this.vehicle.year) {
                return;
            }

            if (_clear_vehicle) {
                this.clearVehicleDetails();
            }

            this.loading = true;
            axios.get('/api/vehicles/model_ids', {
                params: {
                    make: this.vehicle.make,
                    year: this.vehicle.year,
                }
            }).then((response) => {
                this.vehicle_models = response.data.data;
                this.loading = false;
            }).catch((error) => {
                this.vehicle_models = null;
                this.loading = false;
            });
        },
        clearVehicleDetails() {
            this.vehicle.model_id = null;
        },
        hasError(_key) {
            return this.errors !== null && this.errors.hasOwnProperty(_key);
        },
        getError(_key) {
            return '<strong>' + this.errors[_key][0] + '</strong>';
        },
        errorClass(_key, _additional = '') {
            var el_class = 'form-control ' + _additional;
            return this.hasError(_key) ? (el_class + ' is-invalid') : el_class;
        },
    }
}
</script>
